.aleoj-container {
    height: 100%;
}

.aleoj-navbar {
    position: fixed;
}

.aleoj-footer {
    position: fixed;
    bottom: 0;
}

.aleoj-main {
    position: fixed;
    top: 50px;
    width: 100%;
    height: calc(100% - 100px);
    display: flex;
}


.aleoj-main .aleoj-preview {
    flex: 1 1 auto;
    background-color: #272822;
    color: #f8f8f2;
    padding: 1rem;
}

.aleoj-preview-panel-description {
    color: #f8f8f2;
    height: calc(100vh - 200px);
    overflow: scroll;
}

.aleoj-preview-panel-submission {
    color: #f8f8f2;
    height: calc(100vh - 200px);
    overflow: scroll;
}

.aleoj-preview-tab[aria-selected="true"]{
    color: #f8f8f2;
}

.bp3-tab {
    color: #f8f8f2 !important;
}

.bp3-tab[aria-selected="true"], .bp3-tab:not([aria-disabled="true"]):hover{
    color: #f8f8f2 !important;
}


.aleoj-preview .bp3-tab-indicator {
    background-color: aqua;
}

.gutter {
    background-color: #cfcfc2;
    background-repeat: no-repeat;
    background-position: 50%;
}

.gutter.gutter-horizontal {
    cursor: col-resize;
}

.aleoj-main .aleoj-editor {
    background-color: #272822;
}

.aleoj-problems-drawer {
    overflow-y: scroll;
}