.aleoj-submission-table {
    width: 100%;
    background-color: #30404d;
    color: #272822;
}

.aleoj-submission-table th {
    color: #f8f8f2 !important;
}

.aleoj-submission-table td {
    color: #f8f8f2 !important;
}

.aleoj-submission-notes {
    color: #f8f8f2;
    width: 100%;
    text-align: center;
    margin: 1rem;
}

.submission-result {
    line-height: 200%;
}
.submission-code {
    display: block;
    white-space: pre-line;
    margin-bottom: 1rem;
}